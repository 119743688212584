/* eslint-disable no-shadow */
import styled from 'styled-components';

export enum BtnCollors {
  PURPLE = '#8850bf',
  GREEN = '#009E0F',
}

interface BtnRootProps {
  width?: number;
  height?: number;
  bgColor?: keyof typeof BtnCollors;
}

export const BtnRoot = styled.button<BtnRootProps>`
  width: ${(props) => `${props.width}px` || 'auto'};
  height: ${(props) => `${props.height}px` || 'auto'};
  border: none;
  background-color: ${(props) =>
    props.bgColor ? BtnCollors[props.bgColor] : BtnCollors.PURPLE};
  color: white;
  border-radius: 0.1875rem;

  padding: ${(props) => (props.width && props.height ? '0rem' : '0.6875rem')};

  &:disabled {
    opacity: 50%;
    cursor: not-allowed;

    &:hover {
      opacity: 50%;
    }
  }

  &:hover {
    opacity: 85%;
  }
`;
