import { GridColumns, LogsProps } from '../types';

export const mapLogsToColumns = (
  logs: LogsProps[],
  columns: GridColumns,
): any[] => {
  return logs.map((log) => {
    const mappedLog: any = {};

    columns.forEach((column) => {
      const logKey = column.field;
      mappedLog[logKey] = log[logKey];
    });

    return mappedLog;
  });
};
