import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container, ContentButton, GridContainer } from './styles';
import FormDefault from '~/components/FormDefault';
import Loja from '~/components/Loja';
import { LojaContext } from '~/context/loja';
import { InputDate, InputSelect } from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import ToggleDefault from '~/components/ToggleDefault';
import Separator from '~/components/Separator';
import api from '~/services/api';
import {
  GeracaoArqEscFiscalPayload,
  InterfaceSistemaProps,
  SelectType,
  ToggleProps,
} from './types';
import { tipoExportacao } from './utils/tipoExportacao';
import { BsFillLightningFill } from 'react-icons/bs';
import { useExpEscritaFiscal } from './hooks/useExpEscritaFiscal';
import { LogGrid } from './components/LogGrid';
import { Button } from './components/Button';
import { validaIntegridadeDataInicioFim } from '~/utils/functions';
import { toast } from 'react-toastify';
import { getMonth, parseISO } from 'date-fns';
import { checkToggleTrue } from './utils/checkToggleTrue';
import { escritaFiscalServices } from './services';

const ExportacaoEscritaFiscalContent: React.FC = () => {
  const { loja, changeLoja } = useContext(LojaContext);
  const {
    showGrid,
    handleShowGrid,
    disable,
    handleDisable,
    handleCodProcesso,
    codProcesso,
    refetchLogs,
    tipoStatus,
    logs,
    handleTipoStatus,
    resetLogs,
    handlePeriodo,
    handleEnableQuery,
    getDownload,
    urlToDownload,
    handleResetFlgArchiver,
  } = useExpEscritaFiscal();

  const [interfaceSistema, setInterfaceSistema] = useState<SelectType[]>([]);
  const [toggle, setToggle] = useState<ToggleProps>({
    somenteNaoExportados: false,
    marcarTodos: false,
    clientes: false,
    fornecedores: false,
    ecf: false,
  });

  const contentRef = useRef<HTMLDivElement | null>(null);

  const {
    register,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
    resetField,
  } = useForm({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (tipoStatus >= 2 && logs.length > 0) {
      resetField('dta_inicio');
      resetField('dta_fim');

      setValue('tipo_exportacao', tipoExportacao[0]);
      setValue('cod_interface', interfaceSistema[0]);

      if (contentRef.current) {
        const seletorExportar: HTMLInputElement | null =
          contentRef.current.querySelector('[name="tipo_exportacao"]');
        if (seletorExportar) seletorExportar.value = '';
      }

      setToggle({
        somenteNaoExportados: false,
        marcarTodos: false,
        clientes: false,
        fornecedores: false,
        ecf: false,
      });
    }
  }, [getValues, interfaceSistema, logs, resetField, setValue, tipoStatus]);

  const watchInterfaceSistema = watch('cod_interface');
  const watchDtaInicio: string | undefined = watch('dta_inicio');
  const watchDtaFim: string | undefined = watch('dta_fim');

  const isInvalidPeriod = validaIntegridadeDataInicioFim(
    watchDtaInicio,
    watchDtaFim,
  );

  const disableButtonGerarArquivo =
    !loja.cod_loja && watchInterfaceSistema === '';

  const getInterfaceSistema = useCallback(async () => {
    const { data } = await api.get('/interface-sistema');

    if (data.success) {
      const interfaces = data.data as InterfaceSistemaProps[];

      const i = interfaces.map(({ cod_interface, des_interface }) => ({
        label: des_interface,
        value: cod_interface,
      }));

      setInterfaceSistema(i);
      setValue('cod_interface', i[0]);
    }
  }, [setValue]);

  useEffect(() => {
    getInterfaceSistema();
  }, []);

  useEffect(() => {
    handleDisable('buttonGerarArquivo', disableButtonGerarArquivo);
  }, [disableButtonGerarArquivo, handleDisable]);

  useEffect(() => {
    if (
      toggle.marcarTodos &&
      (!toggle.clientes || !toggle.fornecedores || !toggle.ecf)
    ) {
      setToggle((prevToggle) => ({
        ...prevToggle,
        marcarTodos: false,
      }));
    }
  }, [toggle.clientes, toggle.fornecedores, toggle.ecf, toggle.marcarTodos]);

  useEffect(() => {
    setValue('tipo_exportacao', tipoExportacao[0]);
  }, [setValue]);

  const handleGerarArquivo = useCallback(
    async (e) => {
      e.preventDefault();
      if (disableButtonGerarArquivo)
        return toast.warning('Preencha os dados da tela para gerar o arquivo');

      if (isInvalidPeriod) {
        setError('dta_inicio', { type: 'value' });
        setError('dta_fim', { type: 'value' });
        return toast.warning('Verifique se as datas informadas são válidas');
      }
      handleResetFlgArchiver();

      clearErrors('dta_inicio');
      clearErrors('dta_fim');

      const dtaInicio = parseISO(getValues('dta_inicio'));
      const dtaFim = parseISO(getValues('dta_fim'));

      if (getMonth(dtaInicio) !== getMonth(dtaFim)) {
        setError('dta_inicio', { type: 'value' });
        setError('dta_fim', { type: 'value' });
        return toast.warning('Mês entre as datas devem ser idênticos');
      }

      clearErrors('dta_inicio');
      clearErrors('dta_fim');

      if (!checkToggleTrue(toggle))
        return toast.warning(
          'Ao menos uma tabela deve ter sido selecionada (Cliente, Fornecedor ou ECF)',
        );

      if (tipoStatus >= 2 && logs.length >= 3) {
        handleTipoStatus(0);
        resetLogs();
      }

      handlePeriodo(dtaInicio, dtaFim);

      setTimeout(() => {
        handleEnableQuery(true);
      }, 2800);

      const codInterface: number = getValues('cod_interface')?.value;
      const tpExportacao: number = getValues('tipo_exportacao')?.value;

      const geracaoPayload: GeracaoArqEscFiscalPayload = {
        cod_loja: loja.cod_loja,
        cod_interface: codInterface ?? 6,
        dta_inicio: dtaInicio,
        dta_fim: dtaFim,
        tipo_exportacao: tpExportacao,
        flg_som_n_exportado: toggle.somenteNaoExportados,
        flg_ger_cliente: toggle.clientes,
        flg_ger_fornecedor: toggle.fornecedores,
        flg_ger_ecf: toggle.ecf,
      };

      const responseEscritaFiscal = await escritaFiscalServices.storeProcesso(
        geracaoPayload,
      );

      if (responseEscritaFiscal.success) {
        handleCodProcesso(responseEscritaFiscal.data.cod_processo);

        handleDisable('form', true);
        handleDisable('buttonGerarArquivo', true);
        handleShowGrid(true);
      }
    },
    [
      clearErrors,
      disableButtonGerarArquivo,
      getValues,
      handleCodProcesso,
      handleDisable,
      handleEnableQuery,
      handlePeriodo,
      handleShowGrid,
      handleTipoStatus,
      isInvalidPeriod,
      logs,
      loja.cod_loja,
      resetLogs,
      setError,
      tipoStatus,
      toggle,
    ],
  );

  const handleDownload = useCallback(async () => {
    if (tipoStatus >= 2 && codProcesso) {
      await getDownload({ isButtonClick: true });
    }
  }, [codProcesso, getDownload, tipoStatus]);

  return (
    <Container>
      <FormDefault
        codTela={284}
        title="EXPORTAÇÃO DE ESCRITA FISCAL"
        onSave={() => []}
        onCancel={() => []}
        onClearFields={() => []}
        onNew={() => []}
        onDelete={() => []}
        onReturnSearch={() => []}
        isDelete={false}
        isClear={false}
        isSave
        isNew={false}
        isCancel={false}
      >
        <div className="contentHeader">
          <div className="contentHeaderLoja">
            <Loja
              selectedLoja={loja.cod_loja}
              IsInvalid={false}
              onChange={(e) => {
                changeLoja(typeof e === 'number' ? e : loja.cod_loja);
              }}
              isDisabled={disable.form}
            />
          </div>
          <InputSelect
            label="Interface Sistema"
            placeholder="Selecione..."
            name="cod_interface"
            register={register}
            isError={!!errors.cod_interface}
            control={control}
            disabled={disable.form}
            options={interfaceSistema}
            changeSelected={(selected) => {
              setValue('cod_interface', selected);
            }}
            style={{ width: '18.75rem' }}
          />
        </div>
        <div ref={contentRef} className="content">
          <div className="contentDate">
            <InputDate
              id="dta"
              label="Início:"
              name="dta_inicio"
              register={register}
              control={control}
              disabled={disable.form}
              isError={!!errors.dta_inicio}
              onChange={(ev: any) => {
                setValue('dta_inicio', ev.target.value);
              }}
            />
            <InputDate
              id="dta"
              label="Término:"
              name="dta_fim"
              register={register}
              control={control}
              disabled={disable.form}
              isError={!!errors.dta_fim}
              onChange={(ev: any) => {
                setValue('dta_fim', ev.target.value);
              }}
            />
          </div>
          <div className="contentExportar">
            <InputSelect
              label="Exportar"
              placeholder="Selecione..."
              name="tipo_exportacao"
              register={register}
              isError={!!errors.tipo_exportacao}
              control={control}
              disabled={disable.form}
              options={tipoExportacao}
              changeSelected={(selected) => {
                setValue('tipo_exportacao', selected);
              }}
              style={{ width: '18.75rem' }}
            />
            <ToggleDefault
              labelText="Somente não exportados?"
              setChecked={toggle.somenteNaoExportados}
              onSwitch={() => {
                setToggle((prev) => ({
                  ...prev,
                  somenteNaoExportados: !toggle.somenteNaoExportados,
                }));
              }}
              size="large"
              disabled={disable.form}
            />
          </div>
        </div>

        <Separator labelText="Gerar" childrenWidth="8.125rem">
          <ToggleDefault
            labelText="Marcar todos?"
            labelOnRight
            inLine
            setChecked={
              toggle.marcarTodos ||
              (toggle.clientes && toggle.fornecedores && toggle.ecf)
            }
            onSwitch={() => {
              setToggle((prev) => ({
                ...prev,
                marcarTodos: !toggle.marcarTodos,
                clientes: !toggle.marcarTodos,
                fornecedores: !toggle.marcarTodos,
                ecf: !toggle.marcarTodos,
              }));
            }}
            size="large"
            disabled={disable.form}
          />
        </Separator>
        <div className="toggles">
          <ToggleDefault
            labelText="Clientes"
            inLine
            setChecked={toggle.clientes}
            onSwitch={() => {
              setToggle((prev) => ({
                ...prev,
                clientes: !toggle.clientes,
              }));
            }}
            size="large"
            disabled={disable.form}
          />
          <ToggleDefault
            labelText="Fornecedores"
            inLine
            setChecked={toggle.fornecedores}
            onSwitch={() => {
              setToggle((prev) => ({
                ...prev,
                fornecedores: !toggle.fornecedores,
              }));
            }}
            size="large"
            disabled={disable.form}
          />
          <ToggleDefault
            labelText="ECF"
            inLine
            setChecked={toggle.ecf}
            onSwitch={() => {
              setToggle((prev) => ({
                ...prev,
                ecf: !toggle.ecf,
              }));
            }}
            size="large"
            disabled={disable.form}
          />
        </div>

        <ContentButton>
          <Button.Root
            width={300}
            height={40}
            onClick={handleGerarArquivo}
            disabled={disable.buttonGerarArquivo}
          >
            <BsFillLightningFill fontSize="1.25rem" color="#fff" />
            GERAR ARQUIVO
          </Button.Root>
        </ContentButton>
        {showGrid && (
          <GridContainer>
            <Separator
              labelText={`Logs da execução do processo #${codProcesso ?? 0}`}
              sidePadding="0 0"
            />
            <LogGrid />
            <div className="buttons">
              <Button.Root
                disabled={tipoStatus < 2}
                width={170}
                bgColor="GREEN"
                onClick={handleDownload}
              >
                {tipoStatus < 2 ? 'Gerando arquivo...' : 'Baixar arquivo'}
              </Button.Root>
              <Button.Root
                disabled={tipoStatus >= 2}
                onClick={() => refetchLogs()}
                width={120}
              >
                Atualizar
              </Button.Root>
            </div>
          </GridContainer>
        )}
      </FormDefault>
    </Container>
  );
};

export default ExportacaoEscritaFiscalContent;
