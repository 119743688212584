import React, { ComponentProps } from 'react';
import { BtnCollors, BtnRoot } from './styles';

interface Props extends ComponentProps<'button'> {
  width?: number;
  height?: number;
  bgColor?: keyof typeof BtnCollors;
}

/**
 * @component
 * @name ButtonRoot
 * @description Componente base para os botões da tela
 *
 * @param {string} [width=undefined] - Indica a largura do botão
 * @param {string} [height=undefined] - Indica a altura do botão
 * @param {string} [bgColor="PURPLE"] - Indica o background-color do botão se informado, poderá escolher entre "PURPLE" | "GREEN"
 *
 * @returns JSX.Element
 */

export const ButtonRoot: React.FC<Props> = ({
  width,
  height,
  bgColor = 'PURPLE',
  ...rest
}) => {
  return (
    <BtnRoot
      width={width}
      height={height}
      bgColor={bgColor}
      onClick={rest.onClick}
      disabled={rest.disabled}
      style={rest.style}
    >
      {rest.children}
    </BtnRoot>
  );
};
