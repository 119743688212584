/* eslint-disable no-shadow */
export type SelectType = {
  label: string;
  value: string | number;
};

export type InterfaceSistemaProps = {
  cod_interface: number;
  des_interface: string;
};

export type LojaProps = {
  label: string;
  value: number;
  selected: boolean;
  des_loja: string;
  uf: string;
  cod_loja: number;
  num_cnpj: string;
  tipo_regime: number;
};

export type ToggleProps = {
  somenteNaoExportados: boolean;
  marcarTodos: boolean;
  clientes: boolean;
  fornecedores: boolean;
  ecf: boolean;
};

export type GeracaoArqEscFiscalPayload = {
  cod_loja: number;
  cod_interface: number;
  dta_inicio: Date;
  dta_fim: Date;
  tipo_exportacao: number;
  flg_som_n_exportado: boolean;
  flg_ger_cliente: boolean;
  flg_ger_fornecedor: boolean;
  flg_ger_ecf: boolean;
};

type DefaultResponse = {
  count: number;
  fields: any[];
  message: string;
  pagination: { prevPage: number; lastPage: number; startPage: number };
  success: boolean;
  totals: any[];
};

export type LogsProps = {
  id: string;
  cod_log: number;
  cod_loja: number | null;
  cod_processo_esc_fiscal: number;
  cod_processo_sped: number | null;
  des_log: string;
  dta_cadastro: string;
  tipo_processo: number;
};

export type StatusProps = {
  tipo_status: number;
};

export type GeracaoArqEscFiscalResponse = {
  data: { cod_processo: number };
} & DefaultResponse;

export type LogsResponse = {
  data: { logs: LogsProps[]; status: StatusProps };
} & DefaultResponse;

export type GridColumn = {
  field: keyof LogsProps;
  headerName: string;
  disableColumnMenu: boolean;
  headerAlign: 'left' | 'center' | 'right';
  align: 'left' | 'center' | 'right';
  flex?: number;
  width?: number;
};

export type GridColumns = Array<GridColumn>;

export enum TipoStatus {
  NAO_GERADO = 0,
  GERACAO_EM_ANDAMENTO = 1,
  GERACAO_CONCLUIDA = 2,
  ARQUIVOS_BAIXADOS = 3,
}
