import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  DataProps,
  DisableProps,
  ExportacaoEscritaFiscalContextData,
  ExportacaoEscritaFiscalProviderProps,
} from '../types/context';
import { escritaFiscalServices } from '../services';
import { useQuery } from 'react-query';
import { LogsProps, TipoStatus } from '../types';
import { nanoid } from 'nanoid';
import { format, parseISO } from 'date-fns';
import api from '~/services/api';
import { LojaContext } from '~/context/loja';
import { createTagA } from '../utils/createTagA';
import { toast } from 'react-toastify';

export const ExportacaoEscritaFiscalContext = createContext(
  {} as ExportacaoEscritaFiscalContextData,
);

export const ExportacaoEscritaFiscalProvider: React.FC<
  ExportacaoEscritaFiscalProviderProps
> = ({ children }) => {
  const [showGrid, setShowGrid] = useState(false);
  const [disable, setDisable] = useState<DisableProps>({
    form: false,
    buttonGerarArquivo: false,
    buttonGrid: false,
  });
  const [codProcesso, setCodProcesso] = useState<number | null>(null);
  const [logs, setLogs] = useState<LogsProps[]>([]);
  const [tipoStatus, setTipoStatus] = useState(0);
  const [mounted, setMounted] = useState(true);
  const [urlToDownload, setUrlToDownload] = useState<any>(null);
  const [data, setData] = useState<DataProps>({
    dta_inicio: null,
    dta_fim: null,
  });
  const [enableQuery, setEnableQuery] = useState(false);
  const [existsArchiveZip, setExistsArchiveZip] = useState(false);
  const [buttonDownloadClick, setButtonDownloadClick] = useState(false);

  const { loja } = useContext(LojaContext);

  useEffect(() => {
    return () => setMounted(false);
  }, []);

  const handleShowGrid = useCallback((value: boolean) => {
    setShowGrid(value);
  }, []);

  const handleDisable = useCallback(
    (key: keyof DisableProps, value: boolean) => {
      setDisable((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const handleCodProcesso = useCallback((cod: number | null) => {
    setCodProcesso(cod);
  }, []);

  const handleEnableQuery = useCallback((value: boolean) => {
    setEnableQuery(value);
  }, []);

  const getLogs = useCallback(async () => {
    setMounted(true);

    if (codProcesso) {
      const responseLogs = await escritaFiscalServices.indexLogs(codProcesso);

      if (responseLogs.success && mounted) {
        const { logs: logsResponse, status } = responseLogs.data;

        const l = logsResponse.map((log) => ({
          ...log,
          dta_cadastro: format(
            parseISO(log.dta_cadastro),
            'dd/MM/yyyy HH:mm:ss',
          ),
          id: nanoid(),
        }));

        setLogs(l);
        setTipoStatus(status.tipo_status);

        if (status.tipo_status >= 2) {
          setEnableQuery(false);
          toast.success('Processo concluído com sucesso!');
        }
      }
    }
  }, [codProcesso, mounted]);

  const getDownload = useCallback(
    async ({ isButtonClick = false }: { isButtonClick: boolean }) => {
      if (!codProcesso) return;

      setButtonDownloadClick(isButtonClick);

      const resp = await api.get(`/download-escrita-fiscal/${codProcesso}`, {
        responseType: 'blob',
      });

      const url = createTagA(resp.data, codProcesso);

      await escritaFiscalServices.updateStatus(
        TipoStatus.ARQUIVOS_BAIXADOS,
        loja.cod_loja,
        codProcesso,
      );

      const { data: responseLogs } = await escritaFiscalServices.indexLogs(
        codProcesso,
      );

      if (responseLogs.status.tipo_status === TipoStatus.ARQUIVOS_BAIXADOS) {
        const lastLog = responseLogs.logs.reverse().map((log) => ({
          ...log,
          dta_cadastro: format(
            parseISO(log.dta_cadastro),
            'dd/MM/yyyy HH:mm:ss',
          ),
          id: nanoid(),
        }))[0];

        const isDuplicate = logs.some((log) => log.des_log === lastLog.des_log);

        if (!isDuplicate) logs.push(lastLog);
      }

      if (url) {
        setTimeout(() => {
          toast.success('Efetuando download do arquivo...');
        }, 800);
      }
      setUrlToDownload(url);
    },
    [codProcesso, logs, loja?.cod_loja],
  );

  useEffect(() => {
    if (tipoStatus >= 2 && !disable.buttonGerarArquivo && existsArchiveZip) {
      getDownload({ isButtonClick: false });
    }
  }, [disable.buttonGerarArquivo, existsArchiveZip, getDownload, tipoStatus]);

  const handlePeriodo = useCallback((dta_inicio: Date, dta_fim: Date) => {
    setData({ dta_inicio, dta_fim });
  }, []);

  const sendNotification = useCallback(async () => {
    if (urlToDownload && codProcesso) {
      await escritaFiscalServices.storeNotification(
        data.dta_inicio,
        data.dta_fim,
        loja.cod_loja,
        urlToDownload,
        codProcesso,
      );
    }
  }, [
    codProcesso,
    data.dta_fim,
    data.dta_inicio,
    loja.cod_loja,
    urlToDownload,
  ]);

  useEffect(() => {
    if (tipoStatus >= 2 && existsArchiveZip) {
      setDisable((prev) => ({
        ...prev,
        form: false,
        buttonGerarArquivo: false,
      }));

      if (!buttonDownloadClick) sendNotification();
    }
  }, [buttonDownloadClick, existsArchiveZip, sendNotification, tipoStatus]);

  const { refetch: refetchLogs } = useQuery('exp-esc-fisc-logs', getLogs, {
    enabled: enableQuery,
    refetchInterval: 5000,
  });

  const getArchiveZip = useCallback(async () => {
    const existsArchive = await escritaFiscalServices.getArchiveZip(
      Number(codProcesso),
    );

    setExistsArchiveZip(existsArchive);

    if (existsArchive) {
      await escritaFiscalServices.updateStatus(
        TipoStatus.GERACAO_CONCLUIDA,
        loja.cod_loja,
        Number(codProcesso),
      );
    }
  }, [codProcesso, loja?.cod_loja]);

  useQuery('get-archive-esc-fisc-zip', getArchiveZip, {
    enabled: enableQuery && !existsArchiveZip,
    refetchInterval: 5000,
  });

  const handleTipoStatus = useCallback((value: number) => {
    setTipoStatus(value);
  }, []);

  const resetLogs = useCallback(() => {
    setLogs([]);
  }, []);

  const handleResetFlgArchiver = () => {
    setExistsArchiveZip(false);
  };

  const valuesProvider = {
    showGrid,
    handleShowGrid,
    disable,
    handleDisable,
    codProcesso,
    handleCodProcesso,
    logs,
    tipoStatus,
    refetchLogs,
    handleTipoStatus,
    resetLogs,
    handlePeriodo,
    handleEnableQuery,
    getDownload,
    urlToDownload,
    handleResetFlgArchiver,
  };

  return (
    <ExportacaoEscritaFiscalContext.Provider value={valuesProvider}>
      {children}
    </ExportacaoEscritaFiscalContext.Provider>
  );
};
