import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .contentHeader {
    display: flex;
    align-items: center;
    gap: 1.4375rem;

    .contentHeaderLoja {
      width: 100%;
      margin-top: 0.625rem;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 1.4375rem;

    @media only screen and (max-width: 999px) {
      flex-direction: column;
      gap: 0;

      .contentDate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        #dta {
          width: 24.6875rem;
        }
      }
    }

    .contentDate {
      display: flex;
      align-items: center;
      gap: 1.4375rem;

      @media only screen and (max-width: 991px) {
        #dta {
          width: 15.625rem;
        }
      }

      @media only screen and (max-width: 770px) {
        #dta {
          width: 11.25rem;
        }
      }
    }

    .contentExportar {
      display: flex;
      align-items: center;
      gap: 1.4375rem;

      @media only screen and (max-width: 999px) {
        justify-content: flex-start;
        width: 100%;
      }
    }
  }

  .toggles {
    display: flex;
    align-items: center;
    gap: 1.5625rem;
    margin-top: 0.625rem;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export const GridContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;
    margin-top: 0.75rem;
  }
`;
