import api from '~/services/api';
import {
  GeracaoArqEscFiscalPayload,
  GeracaoArqEscFiscalResponse,
  LogsResponse,
} from '../types';

const storeProcesso = async (
  payload: GeracaoArqEscFiscalPayload,
): Promise<GeracaoArqEscFiscalResponse> => {
  const { data } = await api.post<GeracaoArqEscFiscalResponse>(
    '/store-processo',
    payload,
  );
  return data;
};

const indexLogs = async (cod_processo: number): Promise<LogsResponse> => {
  const { data } = await api.get<LogsResponse>(`/logs/${cod_processo}`);
  return data;
};

const updateStatus = async (
  tipo_status: number,
  cod_loja: number,
  cod_processo: number,
): Promise<void> => {
  const { data } = await api.put<void>(`/status-update/${tipo_status}`, {
    cod_loja,
    cod_processo,
  });
  return data;
};

const getArchiveZip = async (cod_processo: number): Promise<boolean> => {
  const { data } = await api.get<boolean>(`/get-archive/${cod_processo}`);
  return data;
};

const storeNotification = async (
  dta_inicio: Date | null,
  dta_fim: Date | null,
  cod_loja: number,
  link: string,
  cod_processo: number,
): Promise<void> => {
  const { data } = await api.post<void>(`/store-notification`, {
    dta_inicio,
    dta_fim,
    cod_loja,
    link,
    cod_processo,
  });
  return data;
};

export const escritaFiscalServices = {
  storeProcesso,
  indexLogs,
  updateStatus,
  getArchiveZip,
  storeNotification,
};
