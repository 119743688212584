export const createTagA = (blob: Blob, cod_processo: number): string => {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = `escrita-fiscal-${cod_processo}.zip`;
  a.rel = 'noreferrer noopener';
  a.target = '_blank';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  return url;
};
