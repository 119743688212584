import React from 'react';

import { ExportacaoEscritaFiscalProvider } from './context/ExportacaoEscritaFiscalContext';
import ExportacaoEscritaFiscalContent from './ExportacaoEscritaFiscalContent';

const ExportacaoEscritaFiscal: React.FC = () => {
  return (
    <ExportacaoEscritaFiscalProvider>
      <ExportacaoEscritaFiscalContent />
    </ExportacaoEscritaFiscalProvider>
  );
};

export default ExportacaoEscritaFiscal;
