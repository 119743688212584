import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import React from 'react';
import { useExpEscritaFiscal } from '../../hooks/useExpEscritaFiscal';
import { mapLogsToColumns } from '../../utils/mapLogsToColumns';
import { GridColumns as GridCol } from '../../types';
import { Tooltip } from '@material-ui/core';

export const LogGrid: React.FC = () => {
  const { logs } = useExpEscritaFiscal();

  const columns: GridColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'cod_log',
      headerName: 'Seq',
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      width: 90,
    },
    {
      field: 'des_log',
      headerName: 'Log',
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        const log = row.des_log;

        return (
          <Tooltip title={log}>
            <span>{log}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'dta_cadastro',
      headerName: 'Data',
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      width: 200,
    },
  ];

  return (
    <div>
      <DataGrid
        rows={mapLogsToColumns(logs, columns as GridCol).slice(0, 4)}
        columns={columns}
        hideFooter
        autoHeight
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Buscando logs...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </div>
  );
};
